<template>
	<header class="header">
        <div class="logo">
            <img src="../assets/creations/logo1.png" alt="Logo">
        </div>
        <nav>
           <ul class="nav-links">
                <li><a href="#proposito">Propósito</a></li>
                <li><a href="#nosotros-container">Nosotros</a></li>
                <li><a href="#family-container">Familia</a></li>
                <li><a href="#creations-root-container">Apps</a></li>
                <li v-if="false"><a href="#container-partners">Colaboraciones</a></li>
           </ul>            
        </nav>
        <a @click="openNav" class="menu"><button><label class="fa-solid fa-bars labelmenu"></label></button></a>
        <div id="mobile-menu" class="overlay">
                <a @click="closeNav" class="close">&times;</a>
                <div class="overlay-content">
                    <a href="#proposito">Propósito</a>
                    <a href="#nosotros-container">Nosotros</a>
                    <a href="#family-container">Familia</a>
                    <a href="#creations-root-container">Apps</a>
                    <a v-if="false" href="#container-partners">Colaboraciones</a>
            </div>
        </div>

    </header>
</template>

<script>
    export default {
        data:()=>({
        }),
        mounted(){

        },
        methods:{
            openNav() {
                document.getElementById("mobile-menu").style.width = "100%";
            },
            closeNav() {
                document.getElementById("mobile-menu").style.width = "0%";
            }
        }
    }
</script>
        

