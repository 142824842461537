

<template>
	<div id="family-container" class="FamiliaGw">
        <h2>NUESTRA FAMILIA</h2> <br>
        
        <h4 v-if="false">AREA DE MARKETING</h4>
        <div class="slide-container swiper" v-if="false">
            <div class="slide-content">
                <div class="card-wrapper swiper-wrapper">                                  
                    <!-- 1 integrante ALISON-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxMark"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/AlisonFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Alison</span>
                                <span class="profession"> Community Manager </span>
                            </div>
                            <div class="about">
                                <p>"Aun en <br> desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 2 integrante CRISTIAN-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxMark"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/CristianFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Cristian</span>
                                <span class="profession"> Community Manager </span>
                            </div>
                            <div class="about">
                                <p>"Aun en <br> desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-button-next swiper-navBtn"></div>
            <div class="swiper-button-prev swiper-navBtn"></div>
            <div class="swiper-pagination"></div>
            
        </div>
        <br>
        <h4 v-if="false">AREA DE ADMINSTRACIÓN, FINANZAS Y RR.HH.</h4>
        <div class="slide-container swiper" v-if="false">
            <div class="slide-content">
                <div class="card-wrapper swiper-wrapper">                           
                    <!-- 1 integrante ALLYSOON-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxAdm"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/AllyFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Allysoon V</span>
                                <span class="profession"> Administradora </span>
                            </div>
                            <div class="about">
                                <p>"Aun en <br> desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 2 integrante ROMINA-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxAdm"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/RominaFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Romina Murga</span>
                                <span class="profession"> Administradora </span>
                            </div>
                            <div class="about">
                                <p>"Aun en <br> desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-button-next swiper-navBtn"></div>
            <div class="swiper-button-prev swiper-navBtn"></div>
            <div class="swiper-pagination"></div>
            
        </div>
        <br>
        <h4 v-if="false">AREA DE PRODUCCIÓN</h4>
        <div class="slide-container swiper" v-if="false">
            <div class="slide-content">
                <div class="card-wrapper swiper-wrapper">               
                    <!-- 1 integrante XIMENA-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/XimenaFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Ximena Bello Perez</span>
                                <span class="profession"> Scrum Master </span>
                            </div>
                            
                            <div class="about">
                                <p>"La energia y la persistencia, <br> conquistan todas las cosas"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 2 integrante ANDRES-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/AndresFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Andrés Gámez</span>
                                <span class="profession"> FrontEnd Developer </span>
                            </div>
                            
                            <div class="about">
                                <p>"Si usas más tu fuerza de voluntad, lograras lo que quieras"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 3 integrante EDSON-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/EdsonFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Edson Ramos</span>
                                <span class="profession"> FrontEnd Programer </span>
                            </div>
                            
                            <div class="about">
                                <p>"La creatividad es la inteligencia divirtiendose"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 4 integrante LENON-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/LennonFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/vevenezuelaflag_111656.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Lennon Sanchez</span>
                                <span class="profession"> BackEnd Developer </span>
                            </div>
                            <div class="about">
                                <p>"Aun en <br> desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 5 integrante OLGA-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/OlgaFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Olga Quiroga</span>
                                <span class="profession"> Developer </span>
                            </div>
                            
                            <div class="about">
                                <p>"Aun en, <br> Desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 6 integrante BRYAN-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/ByamFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Bryam</span>
                                <span class="profession"> Developer </span>
                            </div>
                            
                            <div class="about">
                                <p>"Aun en, <br> Desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 7 integrante ARMANDO-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/ArmandoFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Armando Leonardo</span>
                                <span class="profession"> Developer </span>
                            </div>
                            
                            <div class="about">
                                <p>"Aun en, <br> Desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 8 integrante MIRIAM-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/MiriamFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Miriam Hernandez</span>
                                <span class="profession"> Developer </span>
                            </div>                            
                            <div class="about">
                                <p>"Aun en, <br> Desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 9 integrante CHRISTIAN-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/ChristianFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Christian</span>
                                <span class="profession"> Developer </span>
                            </div>                            
                            <div class="about">
                                <p>"Aun en, <br> Desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 10 integrante NUEVO -->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxDes"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/ChristianFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/Mexico_flags_flag_8930.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Christian</span>
                                <span class="profession"> Developer </span>
                            </div>                            
                            <div class="about">
                                <p>"Aun en, <br> Desarrollo"
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-button-next swiper-navBtn"></div>
            <div class="swiper-button-prev swiper-navBtn"></div>
            <div class="swiper-pagination"></div>
            
        </div>
        <br>
        <h4>EQUIPO</h4>
        <div class="slide-container swiper">
            <div class="slide-content">
                <div class="card-wrapper swiper-wrapper">
                    <!-- 2 integrante JAVIER-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxGer"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/JavierFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Javier Huaynates</span>
                                <span class="profession"> CTO </span>
                            </div>
                            <div class="about">
                                <p>"Uno pone el esfuerzo, <br> Dios da el propósito"
                                </p>
                            </div>                            
                        </div>
                    </div>
                    <!-- 1 integrante ANDRE-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxGer"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/AndreFoto2.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">André López Melgar</span>
                                <span class="profession"> CEO </span>
                            </div>
                            <div class="about">
                                <p>"Vive con propósito, <br> disfruta el proceso"
                                </p>
                            </div>
                            <!-- <div class="button b2">
                                <button class="aboutMe">Sobre Mi</button>
                                <button class="hireMe">Más</button>
                            </div> -->
                        </div>
                    </div>            
                    <!-- 3 integrante KENNETH -->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxGer"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/KennethFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Kenneth Dueñas</span>
                                <span class="profession"> COO </span>
                            </div>
                            <div class="about">
                                <p>"El mayor recurso no es el dinero, <br> son las personas"
                                </p>
                            </div>
                        </div>
                    </div> 
                    <!-- 3 integrante ARTURO -->
                    <div class="swiper-slide card" v-if="false">
                        <div class="box ColorBoxGer"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/ArturoFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Arturo</span>
                                <span class="profession"> CMO </span>
                            </div>
                            <div class="about">
                                <p>"Aun en, <br> Desarrollo"
                                </p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="swiper-button-next swiper-navBtn"></div>
            <div class="swiper-button-prev swiper-navBtn"></div>
            <div class="swiper-pagination"></div>
        </div>
        <br>
        <h4 v-if="false">INVERSIONISTAS</h4>
        <div class="slide-container swiper" v-if="false">
            <div class="slide-content">
                <div class="card-wrapper swiper-wrapper">
                    <!-- 2 integrante DANIEL-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxGer"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/JavierFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Daniel Lopez Galvan</span>
                                <span class="profession"> Inversionista </span>
                            </div>
                            <div class="about">
                                <p>"Uno pone el esfuerzo, <br> Dios da el propósito"
                                </p>
                            </div>                            
                        </div>
                    </div>
                    <!-- 1 integrante TANIA-->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxGer"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/AndreFoto2.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Tania López</span>
                                <span class="profession"> Inversionista </span>
                            </div>
                            <div class="about">
                                <p>"Vive con propósito, <br> disfruta el proceso"
                                </p>
                            </div>
                            <!-- <div class="button b2">
                                <button class="aboutMe">Sobre Mi</button>
                                <button class="hireMe">Más</button>
                            </div> -->
                        </div>
                    </div>            
                    <!-- 3 integrante KENNETH -->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxGer"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/KennethFoto.png" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Kenneth Dueñas</span>
                                <span class="profession"> CTO </span>
                            </div>
                            <div class="about">
                                <p>"El mayor recurso no es el dinero, <br> son las personas"
                                </p>
                            </div>
                        </div>
                    </div> 
                    <!-- 3 integrante ARTURO -->
                    <div class="swiper-slide card">
                        <div class="box ColorBoxGer"> </div>
                        <div class="card-content">
                            <div class="image">
                                <img src="/img/imgEquipo/ArturoFoto.jpeg" alt="">
                            </div>
                            <div class="media-icons">
                                <i class="fab fa-linkedin-in"></i>
                            </div>
                            <div class="media-icons-flag">
                                <img src="/icons/Flags/FlagofPeru_6428.ico" alt="">
                            </div>
                            <div class="name-profession">
                                <span class="name">Arturo</span>
                                <span class="profession"> CMO </span>
                            </div>
                            <div class="about">
                                <p>"Aun en, <br> Desarrollo"
                                </p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="swiper-button-next swiper-navBtn"></div>
            <div class="swiper-button-prev swiper-navBtn"></div>
            <div class="swiper-pagination"></div>
        </div>
        <br>
    </div>
</template>


<script>
import Swiper from 'swiper';

	export default {
		name:'family-component',
		data(){
			return {
				focusIndex:null,
				focusMeta:null,
				rows:[
					{ 
						data:[
							{
								title:'Equipo de Gerencia',
								subtitle:'André López (CEO)',
								description:'“Vive con proposito y disfruta el proceso”',
								flagDir:require('@/assets/flags/peru.png'),
								preview:require('@/assets/preview/Andre.png')
							},{
								title:'Equipo de Gerencia',
								subtitle:'Javier Huaynates (COO)',
								description:'“Uno pone el esfuerzo y Dios da el propósito”',
								flagDir:require('@/assets/flags/peru.png'),
								preview:require('@/assets/preview/Javier.png')
							},{
								title:'Equipo de Gerencia',
								subtitle:'Kenneth Dueñas (CTO)',
								description:'“El mayor recurso no es el dinero, son las personas”',
								flagDir:require('@/assets/flags/peru.png'),
								preview:require('@/assets/preview/kenneth.png')
							},{
								title:'Equipo de Administración',
								subtitle:'Romina Murga',
								description:'',
								flagDir:require('@/assets/flags/peru.png'),
								preview:require('@/assets/preview/Romina.png')
							},
							// production team
							{
								title:'Equipo de Producción',
								subtitle:'Andrés Gámez',
								description:'“Si usas más tu fuerza de voluntad, lograrás lo que quieras”',
								flagDir:require('@/assets/flags/mexico.png'),
								preview:require('@/assets/preview/Andres.png')
							},{
								title:'Equipo de Producción',
								subtitle:'Ximena Bello',
								description:'“La energía y la persistencia conquistan todas las cosas”',
								flagDir:require('@/assets/flags/mexico.png'),
								preview:require('@/assets/preview/Ximena.png')
							},{
								title:'Equipo de Producción',
								subtitle:'Edson Ramos',
								description:'“La creatividad es la inteligencia divirtiéndose”',
								flagDir:require('@/assets/flags/mexico.png'),
								preview:require('@/assets/preview/Edson.png')
							},
							{
								title:'Equipo de Producción',
								subtitle:'Lennon Sánchez',
								description:'',
								flagDir:require('@/assets/flags/venezuela.png'),
								preview:require('@/assets/preview/Lennon.png')
							},
						]
					},
					// {
					// 	data:[
					// 		{
					// 			title:'Equipo de Producción',
					// 			subtitle:'Andrés Gámez',
					// 			description:'“Si usas más tu fuerza de voluntad, lograrás lo que quieras”',
					// 			flagDir:require('@/assets/flags/mexico.png'),
					// 			preview:require('@/assets/preview/Andres.png')
					// 		},{
					// 			title:'Equipo de Producción',
					// 			subtitle:'Ximena Bello',
					// 			description:'“La energía y la persistencia conquistan todas las cosas”',
					// 			flagDir:require('@/assets/flags/mexico.png'),
					// 			preview:require('@/assets/preview/Ximena.png')
					// 		},{
					// 			title:'Equipo de Producción',
					// 			subtitle:'Edson Ramos',
					// 			description:'“La creatividad es la inteligencia divirtiéndose”',
					// 			flagDir:require('@/assets/flags/mexico.png'),
					// 			preview:require('@/assets/preview/Edson.png')
					// 		},{
					// 			title:'Equipo de Producción',
					// 			subtitle:'Mateo Jimenez',
					// 			description:'',
					// 			flagDir:require('@/assets/flags/colombia.png'),
					// 			preview:require('@/assets/preview/Mateo.png')
					// 		},
					// 		{
					// 			title:'Equipo de Producción',
					// 			subtitle:'Lennon Sánchez',
					// 			description:'',
					// 			flagDir:require('@/assets/flags/venezuela.png'),
					// 			preview:require('@/assets/preview/lennon.jpg')
					// 		},
					// 	]
					// }
				]
			}
		},
		mounted(){
			var swiper = new Swiper(".slide-content", {
				slidesPerView: 5,
				spaceBetween: 20,
				loop: true,
				loopFillGroupWithBlank: true,
				centerSlide:'true',
				fade:'true',
				grabCursor: 'true',
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
					dynamicBullets: true,
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},  
				breakpoints: {
					0: {
						slidesPerView: 1,
					},
					500: {
						slidesPerView: 2,
					},
					900: {
						slidesPerView: 3,
					},
					1200: {
						slidesPerView: 4,
					},
					1600: {
						slidesPerView: 5,
					}
				}
			});

		},
		methods:{
			onShowModal(index,e) {
				this.focusIndex = index
				this.focusMeta = e.target.getBoundingClientRect()
			},
			onCloseModal() {
				this.focusIndex = null
				this.focusMeta = null
				document.body.style.overflow = 'auto'
			}
		}
	}
</script>




